<script>
const fillColors = ['#FF9801', '#262d44', '#8DC129']
let searchShippingGetInterval = null
let documentationTimeout = null
export default {
  name: 'BusinessTTKContent',
  props: {
    customer: {
      type: Object,
      default: () => {}
    },
    height: {
      type: String,
      default: null
    }
  },
  data: (vm) => ({
    menu: false,
    customerProjectSelected: null,
    dataResult: null,
    tab: null,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    loadingUpload: false,
    loadingDownload: false,
    optionsBar: {
      colors: fillColors,
      chart: {
        events: {
          animationEnd: () => {
            vm.SYNC_CUSTOM_GRAPH(true)
          },
          updated: () => {
            vm.graphLoaded = false
          }
        },
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 0,
        colors: ['#ff0000']
      },
      title: {
        enabled: false,
        text: 'Spread by Provinces',
        align: 'left'
      },
      // subtitle: {
      //   text: '100% Stacked Bar',
      //   align: 'left'
      // },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: true
        }
      },
      yaxis: {
        logBase: 15,
        labels: {
          offsetX: -80,
          formatter: function (value) {
            return value
          }
        },
        axisBorder: {
          show: false
        }
      },
      tooltip: {
        enabled: true,
        fillSeriesColor: false,
        y: {
          formatter: function (val, opt) {
            return (val).toLocaleString() + '/' + vm.TotalInProvice(opt.w.globals.labels[opt.dataPointIndex]) + ' TTK'
          }
        }
      },
      fill: {
        colors: fillColors
        // type: 'gradient',
        // gradient: {
        //   shade: 'dark',
        //   type: 'horizontal',
        //   shadeIntensity: 0.5,
        //   gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        //   inverseColors: true,
        //   opacityFrom: 1,
        //   opacityTo: 0.9,
        //   stops: [0, 50, 100],
        //   colorStops: []
        // }
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        offsetX: 0,
        marker: {
          fillColors: fillColors
        },
        formatter: (seriesName, opts) => {
          return `
            <div class="d-inline-block">
              <strong class="${(opts.seriesIndex === 2 ? 'secondary--text' : (opts.seriesIndex === 1 ? 'primary--text' : 'orange--text'))}">${seriesName}</strong>
              <div class="font-weight-bold">${vm.LegendTotal(opts.w.globals.series[opts.seriesIndex])}</div>
            </div>
          `
        }
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff'],
          fontSize: '8px'
        },
        // formatter: function (val, opt) {
        //   // return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + ((val).toLocaleString() + ' TTK')
        //   return (val).toLocaleString() + ' ttk'
        // },
        offsetX: 0,
        dropShadow: {
          enabled: false
        }
      },
      grid: {
        show: false,
        borderColor: '#ff0000',
        strokeDashArray: 2,
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          top: 20,
          right: 10,
          bottom: 0,
          left: 100
        }
        // row: {
        //   colors: ['#F44336', '#E91E63', '#9C27B0']
        // },
        // column: {
        //   colors: ['#F44336', '#E91E63', '#9C27B0']
        // }
      }
    },
    seriesBar: [
      {
        name: 'On Transport',
        data: []
      }, {
        name: 'On Delivery',
        data: []
      }, {
        name: 'Delivered',
        data: []
      }
    ],
    shippingGetData: {
      loading: false,
      query: '',
      page: 1,
      limit: 25,
      total: 0,
      data: [],
      options: {
      },
      headers: [
        { text: 'No TTK', value: 'ttk', sortable: false },
        { text: 'Asal - Tujuan', value: 'shipping_origin_city_name', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Options', value: 'option', sortable: false }
      ]
    },
    dialogDelivered: {
      show: false,
      data: null
    },
    dialogPlan: {
      show: false,
      data: null
    },
    filterStatus: 2,
    provincesDataSelected: 0,
    provincesData: [],
    graphRender: true,
    graphList: {},
    graphActive: 0,
    lastUpdateData: null,
    wHeader: {
      prov: '128px',
      dest: '103px',
      progress: '100%'
    },
    documentation: {
      data: [],
      query: '',
      category: '',
      page: 1,
      total: 0,
      dialog: {
        show: false,
        loading: false,
        data: null
      }
    },
    graphLoaded: false,
    dataProject: [],
    dataProjectGroups: {}
  }),
  beforeMount () {
    // this.InitLoad()
    // this.ReqLastUpdate()
  },
  mounted () {
    this.PROJECT_GET()
  },
  methods: {
    PROJECT_GET () {
      this.dataProject = []
      this.$store.dispatch('logistic/PROJECT_GET', '?q=').then((res) => {
        if (res.status) {
          this.dataProject = res.data.data || []
        }
      })
    },
    onGraphResize (e) {
      if (this.graphLoaded) {
        this.SYNC_CUSTOM_GRAPH()
      }
    },
    SYNC_CUSTOM_GRAPH (firstLoad) {
      this.wHeader.dest = '0px'
      if (this.tab !== 'tab-1') {
        return false
      }
      if (firstLoad) {
        this.graphLoaded = true
      }
      setTimeout(() => {
        const cContainer = document.querySelector('.content-schools')
        if (cContainer) {
          cContainer.setAttribute('style', 'visibility:hidden;')
        }
        const yaxisEl = document.querySelectorAll('.apexcharts-yaxis>.apexcharts-yaxis-texts-g>text>tspan')
        const hBarsMain = document.querySelector('.card-parent-chart')
        // const hBarsMainMaster = document.querySelector('.apexcharts-canvas>.apexcharts-svg>.apexcharts-graphical>.apexcharts-bar-series')
        let hBars = document.querySelectorAll('.apexcharts-canvas>.apexcharts-svg>.apexcharts-graphical>.apexcharts-bar-series>.apexcharts-series[seriesName="OnxTransport"]>path')
        let hBarsRect = hBars[0].getBoundingClientRect()
        if (hBarsRect && !hBarsRect.width) {
          hBars = document.querySelectorAll('.apexcharts-canvas>.apexcharts-svg>.apexcharts-graphical>.apexcharts-bar-series>.apexcharts-series[seriesName="OnxDelivery"]>path')
          hBarsRect = hBars[0].getBoundingClientRect()
          if (hBarsRect && !hBarsRect.width) {
            hBars = document.querySelectorAll('.apexcharts-canvas>.apexcharts-svg>.apexcharts-graphical>.apexcharts-bar-series>.apexcharts-series[seriesName="Delivered"]>path')
          }
        }
        let yaxisElRectWidth = 0
        for (let f = 0; f < yaxisEl.length; f++) {
          const el = yaxisEl[f].getBoundingClientRect()
          if (yaxisElRectWidth < el.width) {
            yaxisElRectWidth = Math.round(el.width)
          }
        }

        let hBarsWidth = 0
        let hBarsHeight = 0
        let hBarsMarginBottom = 0
        for (let f = 0; f < hBars.length; f++) {
          const el = hBars[f]
          const elRect = el.getBoundingClientRect()
          if (hBarsWidth < elRect.width) {
            hBarsWidth = Math.round(elRect.width)
            hBarsHeight = elRect.height
          }
          if (f === 0) {
            hBarsMarginBottom = elRect.y
          } else if (f === 1) {
            hBarsMarginBottom = Math.abs((elRect.y - hBarsMarginBottom) - elRect.height) - (0.02 * hBars.length)
          }
        }

        const barAreaWidth = (hBarsMain ? hBarsMain.getBoundingClientRect() : {}).width || 0
        const wBar = (yaxisElRectWidth + 20) * 100 / barAreaWidth
        this.wHeader.prov = (wBar + 1.8) + '%'
        // const barAreaHeight = (hBarsMain ? hBarsMain.getBoundingClientRect() : {}).height || 0
        // const barAreaHeightMaster = (hBarsMainMaster ? hBarsMainMaster.getBoundingClientRect() : {}).height || 0
        this.wHeader.dest = (91 - ((hBarsWidth) * 100 / barAreaWidth) - wBar) + '%'
        for (let e = 0; e < yaxisEl.length; e++) {
          // const el = yaxisEl[e]
          const elToInject = document.querySelector(`.content-schools>.index-${e}`)
          if (elToInject) {
            elToInject.parentElement.setAttribute('style', `width:${this.wHeader.dest};left:${wBar + 4}%;visibility:visible;top:calc(95px - ${0.01 * hBars.length}%)`)
            elToInject.setAttribute('style', `margin-bottom:${hBarsMarginBottom}px;top:0px;left:0px;width:100%;height:${Math.round(hBarsHeight)}px;`)
            const elOndelivery = document.querySelector(`.content-schools>.index-${e}>.in-progress`)
            elOndelivery.setAttribute('style', 'width:0%;transition:all .15s ease-in-out;')
            const prov = (yaxisEl[e].innerHTML || '').replace('...', '').split('(')[0]
            const p = this.provincesData.find(r => (r.prov_name || '').trim().match(prov))
            if (p) {
              const countTotal = parseInt(p.total) || 0
              elOndelivery.innerHTML = countTotal.toLocaleString()
            }
            elOndelivery.setAttribute('style', 'width:100%;transition:all .15s ease-in-out;')
          }
        }
      }, 300)
    },
    DO_LOGOUT () {
      if (!confirm('Logout ?')) {
        return false
      }
      localStorage.removeItem('t')
      this.$store.commit('USER', null)
    },
    ReqLastUpdate () {
      this.$store.dispatch('logistic/LAST_UPDATE', { project: this.customerProjectSelected.id })
        .then((res) => {
          if (res && res.status && res.data && res.data.data) {
            this.lastUpdateData = res.data.data
          }
        })
    },
    LegendTotal (d) {
      const dd = d.reduce((partialSum, a) => partialSum + a, 0) || 0
      const ddPercent = (dd * 100 / (this.dataResult.total || 0)).toFixed(2)
      return ddPercent + '% (' + (dd).toLocaleString() + '/' + (this.dataResult.total || 0).toLocaleString() + ')'
    },
    DeleteImage (img) {
      if (!confirm('Delete this image?')) {
        return false
      }
      const imgs = this.$StrToArray(this.dialogDelivered.data.recipient_imgs)
      const imgsIndex = imgs.findIndex(r => r === img)
      if (imgsIndex > -1) {
        imgs.splice(imgsIndex, 1)
        this.dialogDelivered.data.recipient_imgs = this.$ArrayToStr(imgs)
      }
    },
    TotalInProvice (prov) {
      const p = this.provincesData.find(r => (r.prov_name || '').trim() === (prov || '').trim())
      if (p) {
        // return ((p.processed + p.delivered) || 0).toLocaleString()
        return ((p.total) || 0).toLocaleString()
      }
      return '~'
    },
    InitLoad () {
      this.dataResult = null
      this.graphList = {}
      this.seriesBar[0].data = []
      this.seriesBar[1].data = []
      this.seriesBar[2].data = []
      this.optionsBar.xaxis.categories = []
      this.$store.dispatch('logistic/REPORT_GET', this.customerProjectSelected.id)
        .then((res) => {
          if (res && res.status && res.data.data) {
            this.dataResult = res.data.data
            this.provincesData = []
            this.dataProjectGroups = res.data.data.groups || {}
            for (let x = 0; x < this.dataResult.provinces.length; x++) {
              const d = this.dataResult.provinces[x]
              const processed = d.processed
              const delivered = d.delivered
              // const total = d.processed + d.delivered
              this.seriesBar[0].data.push(d.total - (processed + delivered))
              this.seriesBar[1].data.push(processed)
              this.seriesBar[2].data.push(delivered)
              this.optionsBar.xaxis.categories.push(d.prov_name)
              this.provincesData.push(d)
              this.graphList[d.prov_name] = {
                index: x,
                total: d.total,
                processed,
                delivered
              }
            }
          }
        })
      this.$nextTick(() => {
        window.dispatchEvent(new Event('resize'))
      })
    },
    PLAN_SHOW (item) {
      this.dialogPlan.selected = Object.assign({}, item)
      this.dialogPlan.show = true
    },
    SHIPPING_GET (isFirstLoad) {
      if (!isFirstLoad) {
        this.shippingGetData.data = []
        this.shippingGetData.total = 0
      }
      const page = this.shippingGetData.page
      const limit = this.shippingGetData.limit
      const pageAndLimit = `&page=${page}&limit=${limit}`
      this.shippingGetData.loading = true
      this.$store.dispatch('logistic/REPORT_SHIPPING_GET', ('?pid=' + this.customerProjectSelected.id) + (this.shippingGetData.query ? ('&q=' + this.shippingGetData.query) : '') + ('&province=' + (this.provincesDataSelected || 0)) + ('&status_progress=' + (this.filterStatus || 0) + pageAndLimit))
        .then((res) => {
          if (res.status) {
            this.shippingGetData.data = res.data.data.data
            this.shippingGetData.total = parseInt(res.data.data.total) || 0
          } else {
            this.shippingGetData.data = []
            this.shippingGetData.total = 0
          }
          this.shippingGetData.loading = false
        })
    },
    ConfirmDelivered (plan, sname) {
      const PlatSplit = plan.split('-|-')
      this.dialogDelivered.data = {
        id: (plan ? parseInt(PlatSplit[0]) : 0) || 0,
        recipient_name: (plan ? PlatSplit[5] : '') || sname || '',
        recipient_relation: (plan ? PlatSplit[6] : '') || 'Pengelola Perpustakaan',
        recipient_imgs: (plan ? PlatSplit[7] : '') || '[]',
        recipient_notes: (plan ? PlatSplit[8] : '') || ((parseInt(this.customerProjectSelected.id) === 30134) ? '1 dus rak diterima dalam kondisi baik' : ''),
        recipient_time: (plan ? PlatSplit[9] : '') || (new Date()).toISOString(),
        recipient_sign: ''
      }
      this.dialogDelivered.show = true
    },
    ProcessDelivered () {
      const data = Object.assign({}, this.dialogDelivered.data)
      if (!parseInt(data.id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid resource!' })
        return false
      }
      // if (!data.recipient_imgs || data.recipient_imgs === '[]') {
      //   this.$store.dispatch('TOAST', { show: true, message: 'Please upload an image(s)!' })
      //   return false
      // }
      if (!data.recipient_name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Name is empty!' })
        return false
      }
      if (!data.recipient_time || !(new Date(data.recipient_time))) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please select datetime!' })
        return false
      }
      if ((new Date(data.recipient_time)) > (new Date())) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid datetime!' })
        return false
      }
      if (!confirm('Process data ?')) {
        return false
      }
      data.recipient_time = (new Date(data.recipient_time).toISOString())
      // this.dialogDelivered.data.recipient_time = (new Date()).toISOString()
      this.$store.dispatch('logistic/CONFIRM_DELIVERED', this.dialogDelivered.data)
        .then((res) => {
          if (res.status) {
            if (res.data.data) {
              this.dialogDelivered.show = false
              this.SHIPPING_GET()
              this.$store.dispatch('TOAST', { show: true, message: 'Success!' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Failed, please try again!' })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Ops, terjadi kesalahan!' })
          }
        })
    },
    DOCUMENTATION_OPEN (data) {
      if (data) {
        this.documentation.dialog.data = Object.assign({}, data)
      } else {
        this.documentation.dialog.data = {
          title: '',
          category: '',
          description: '',
          files: '[]',
          customer: 0,
          project: this.customerProjectSelected.id
        }
      }
      this.documentation.dialog.show = true
    },
    async DOCUMENTATION_PROCESS () {
      const data = Object.assign({}, this.documentation.dialog.data)
      if (!data.title) {
        this.$store.dispatch('TOAST', { show: true, message: 'Judul belum diisi!' })
        return false
      }
      if (!data.category) {
        this.$store.dispatch('TOAST', { show: true, message: 'Kategori belum dipilih!' })
        return false
      }
      if (!data.files || data.files === '[]') {
        this.$store.dispatch('TOAST', { show: true, message: 'Video belum diupload!' })
        return false
      }
      this.documentation.dialog.loading = true
      const documentation = await this.$store.dispatch('logistic/DOCUMENT_PROCESS', data)
      if (documentation && documentation.status && documentation.data) {
        this.documentation.dialog.loading = false
        this.documentation.dialog.data = null
        this.documentation.dialog.show = false
        this.DOCUMENTATION_GET()
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
      }
    },
    async DOCUMENTATION_GET () {
      this.documentation.data = []
      this.documentation.total = 0
      const documentation = await this.$store.dispatch('logistic/DOCUMENT_GET', `?pid=${this.customerProjectSelected.id}&category=${(this.documentation.category || '')}&q=${(this.documentation.query || '')}&page=${(parseInt(this.documentation.page) || 1)}&limit=6`)
      if (documentation && documentation.status && documentation.data && documentation.data.data) {
        this.documentation.data = documentation.data.data.data || []
        this.documentation.total = parseInt(documentation.data.data.total) || 0
      }
    },
    async GoUpload (f) {
      const file = Math.round((f.size / 1024))
      if (file >= 2000000000) {
        this.$store.dispatch('TOAST', { show: true, message: 'File terlalu besar!' })
        return false
      }
      const d = new FormData()
      d.append('file', f)
      this.loadingUpload = true
      await this.$store.dispatch('UPLOAD_FILE_BINARY', { data: d, query: `?project=${this.customerProjectSelected.id}&source=documentation` }).then((resUpload) => {
        this.loadingUpload = false
        if (resUpload.status) {
          if (resUpload.data.data.res) {
            const vids = [resUpload.data.data.secure_url]
            this.documentation.dialog.data.files = this.$ArrayToStr(vids)
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Ops, failed upload' })
          }
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Ops, please try again' })
        }
        this.loadingUpload = false
      })
      return true
    },
    UploadPrepare () {
      const idEl = document.getElementById('file-uploader')
      if (idEl) {
        document.parentElement.removeChild(idEl)
      }
      const f = document.createElement('input')
      f.setAttribute('type', 'file')
      f.setAttribute('id', 'file-uploader')
      f.setAttribute('class', 'file-uploader')
      // f.setAttribute('multiple', '')
      f.setAttribute('accept', '.avi,.mp4,.flv,.jpg,.png,.jpeg')
      f.addEventListener('change', async (e) => {
        const files = e.target.files
        if (!files.length) {
          this.$store.dispatch('TOAST', { show: true, message: 'No file selected.' })
          return false
        }
        this.loadingUpload = true
        for (let i = 0; i < files.length; i++) {
          await this.GoUpload(files[i])
        }
        this.loadingUpload = false
      })
      f.click()
    },
    async GET_FILE () {
      if (!this.loadingUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('multiple', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg,.pdf')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e)
            // if (image.dataUrl) {
            //   this.loadingUpload = true
            //   this.UPLOAD_FILE(image.dataUrl)
            // } else {
            //   this.$store.dispatch('TOAST', { show: true, message: 'Url parse failed!' })
            //   this.loadingUpload = false
            // }
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (rr) {
        setTimeout(async () => {
          const res = rr
          if (res && res.ext) {
            // SINGLE FILE
            if (res.status) {
              $.loadingUpload = true
              await $.$store.dispatch('UPLOAD_FILE', {
                file: res.data,
                source: 'shipping'
              }).then((resUpload) => {
                $.loadingUpload = false
                if (resUpload.status) {
                  if (resUpload.data.data.res) {
                    const imgURL = resUpload.data.data.secure_url || null
                    if (!imgURL) {
                      $.$store.dispatch('TOAST', { show: true, message: 'Invalid response file uri!' })
                      return false
                    }
                    const imgs = $.$StrToArray($.dialogDelivered.data.recipient_imgs, true)
                    imgs.push(imgURL)
                    $.dialogDelivered.data.recipient_imgs = $.$ArrayToStr(imgs)
                    $.$store.dispatch('TOAST', { show: true, message: 'Uploaded' })
                  } else {
                    $.$store.dispatch('TOAST', { show: true, message: 'Ops, failed upload' })
                  }
                } else {
                  $.$store.dispatch('TOAST', { show: true, message: 'Ops, please try again' })
                }
              })
            } else {
              res.data === 'file_size'
                ? $.$store.dispatch('TOAST', { show: true, message: 'File size no more than 20Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
                : res.data === 'canceled'
                  ? console.log('Upload canceled.')
                  : console.log(res.data)
            }
          } else if (res.length) {
            if (res.find(r => r.data === 'file_size')) {
              $.$store.dispatch('TOAST', { show: true, message: 'Please upload file no more than 20Mb' })
              return false
            } else if (res.find(r => !r.status)) {
              $.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
              return false
            } else {
              let uploadIteration = 0
              $.loadingUpload = true
              for (let u = 0; u < res.length; u++) {
                uploadIteration++
                await $.$store.dispatch('UPLOAD_FILE', {
                  file: res[u].data,
                  source: 'shipping'
                }).then((resUpload) => {
                  if (uploadIteration === res.length) {
                    $.loadingUpload = false
                  }
                  if (resUpload.status) {
                    if (resUpload.data.data.res) {
                      const imgURL = resUpload.data.data.secure_url || null
                      if (!imgURL) {
                        $.$store.dispatch('TOAST', { show: true, message: 'Invalid response img uri' })
                        return false
                      }
                      const imgs = $.$StrToArray($.dialogDelivered.data.recipient_imgs)
                      imgs.push(imgURL)
                      $.dialogDelivered.data.recipient_imgs = $.$ArrayToStr(imgs)
                      $.$store.dispatch('TOAST', { show: true, message: (u + 1) + ' Uploaded!' })
                    } else {
                      $.$store.dispatch('TOAST', { show: true, message: ' Ops, failed upload!' })
                    }
                  } else {
                      $.$store.dispatch('TOAST', { show: true, message: 'Ops, please try again!' })
                  }
                })
              }
            }
          }
        }, 750)
      })
    },
    async DownloadFiles (isCompress) {
      if (isCompress) {
        if (!confirm('Download all files? The file size is around 3.5 GB and it may take up to 15 minutes to compress before the file can be downloaded!')) {
          return false
        }
        this.loadingDownload = true
        await this.$store.dispatch('DOWNLOAD_ZIP', { project: this.customerProjectSelected.id }).then((resDownload) => {
          this.loadingDownload = false
          if (resDownload && resDownload.status && resDownload.data && resDownload.data.data) {
            const a = document.createElement('a')
            a.setAttribute('href', resDownload.data.data)
            a.setAttribute('target', '_blank')
            a.click()
          } else {
            this.$store.dispatch('TOAST', { show: true, message: ' Ops, request failed!' })
          }
        })
      } else {
        const a = document.createElement('a')
        a.setAttribute('href', `https://dashboard.anterocargo.id/static/${this.customerProjectSelected.id}.zip`)
        a.setAttribute('target', '_blank')
        a.click()
      }
    },
    async DOWNLOAD_FILES_SEPARATE (planid) {
      this.loadingDownload = true
      await this.$store.dispatch('DOWNLOAD_ZIP_SEPARATE', { planid, project: this.customerProjectSelected.id }).then((resDownload) => {
        this.loadingDownload = false
        if (resDownload && resDownload.status && resDownload.data && resDownload.data.data) {
          if (resDownload.data.data === 'FAILED') {
            this.$store.dispatch('TOAST', { show: true, message: ' Ops, file not found!' })
          } else {
            const a = document.createElement('a')
            a.setAttribute('href', resDownload.data.data)
            a.setAttribute('target', '_blank')
            a.click()
          }
        } else {
          this.$store.dispatch('TOAST', { show: true, message: ' Ops, request failed!' })
        }
      })
    }
  },
  watch: {
    customerProjectSelected: {
      immediate: true,
      deep: true,
      handler (v) {
        if (v) {
          this.InitLoad()
          if (this.tab === 'tab-2') {
            this.SHIPPING_GET(true)
          } else if (this.tab === 'tab-3') {
            this.documentation.page = 1
            this.DOCUMENTATION_GET()
          } else if (this.tab === 'tab-1') {
            this.shippingGetData.page = 1
            this.ReqLastUpdate()
            // this.InitLoad()
          }
          this.onGraphResize()
        }
      }
    },
    'documentation.query': {
      immediate: false,
      handler (v) {
        if (documentationTimeout) {
          clearTimeout(documentationTimeout)
        }
        documentationTimeout = setTimeout(() => {
          this.DOCUMENTATION_GET()
        }, 300)
      }
    },
    'documentation.category': {
      immediate: false,
      handler (v) {
        this.DOCUMENTATION_GET()
      }
    },
    'documentation.page': {
      immediate: false,
      handler (v) {
        this.DOCUMENTATION_GET()
      }
    },
    'shippingGetData.page': {
      immediate: false,
      handler (v) {
        this.SHIPPING_GET()
      }
    },
    graphActive: {
      immediate: false,
      handler (v) {
        const cContainer = document.querySelector('.content-schools')
        if (cContainer) {
          cContainer.setAttribute('style', 'visibility:hidden;')
        }
        this.seriesBar[0].data = []
        this.seriesBar[1].data = []
        this.seriesBar[2].data = []
        this.optionsBar.xaxis.categories = []
        this.graphRender = false
        for (const [key, value] of Object.entries(this.graphList)) {
          if (v === 0) {
            this.seriesBar[0].data.push(value.total - (value.processed + value.delivered))
            this.seriesBar[1].data.push(value.processed)
            this.seriesBar[2].data.push(value.delivered)
            this.optionsBar.xaxis.categories.push(key)
          } else {
            if (this.dataProjectGroups[v].find(r => r === key)) {
              const total = parseInt(value.total - (value.processed + value.delivered))
              this.seriesBar[0].data.push(total)
              this.seriesBar[1].data.push(value.processed)
              this.seriesBar[2].data.push(value.delivered)
              this.optionsBar.xaxis.categories.push(key)
            }
          }
        }
        const series0 = this.seriesBar[0].data.reduce((partialSum, a) => partialSum + a, 0) || 0
        const series1 = this.seriesBar[1].data.reduce((partialSum, a) => partialSum + a, 0) || 0
        const series2 = this.seriesBar[2].data.reduce((partialSum, a) => partialSum + a, 0) || 0
        this.dataResult.total = series0 + series1 + series2
        this.dataResult.progress.processed = series1
        this.dataResult.progress.delivered = series2
        this.dataResult.total_provinces = this.optionsBar.xaxis.categories.length
        this.$nextTick(() => {
          this.graphRender = true
        })
      }
    },
    filterStatus: {
      immediate: false,
      handler (v) {
        if (parseInt(this.shippingGetData.page) !== 1) {
          this.shippingGetData.page = 1
        } else {
          if (searchShippingGetInterval) {
            clearInterval(searchShippingGetInterval)
          }
          searchShippingGetInterval = setTimeout(() => {
            this.SHIPPING_GET()
          }, 500)
        }
      }
    },
    provincesDataSelected: {
      immediate: false,
      handler (v) {
        if (parseInt(this.shippingGetData.page) !== 1) {
          this.shippingGetData.page = 1
        } else {
          if (searchShippingGetInterval) {
            clearInterval(searchShippingGetInterval)
          }
          searchShippingGetInterval = setTimeout(() => {
            this.SHIPPING_GET()
          }, 500)
        }
      }
    },
    'shippingGetData.query': {
      immediate: false,
      handler (v) {
        if (searchShippingGetInterval) {
          clearInterval(searchShippingGetInterval)
        }
        searchShippingGetInterval = setTimeout(() => {
          if (parseInt(this.shippingGetData.page) !== 1) {
            this.shippingGetData.page = 1
          } else {
            this.SHIPPING_GET()
          }
        }, 500)
      }
    },
    tab: {
      immediate: false,
      handler (v) {
        if (v === 'tab-2') {
          if (searchShippingGetInterval) {
            clearInterval(searchShippingGetInterval)
          }
          searchShippingGetInterval = setTimeout(() => {
            this.SHIPPING_GET(true)
          }, 500)
        } else if (v === 'tab-3') {
          this.documentation.page = 1
          this.DOCUMENTATION_GET()
        } else {
          this.shippingGetData.page = 1
          this.ReqLastUpdate()
          // this.InitLoad()
        }
      }
    }
  }
}

</script>

<template>
  <v-card flat class="rounded-0 elevation-0" :style="{ height: (height || 'calc(100vh - 90px)') }">
    <v-toolbar
      dark
      flat
      color="bg-business-primary"
    >
      <v-toolbar-title>
        <!-- :disabled="$store.state.DashHostName === 'business.anterocargo.id'" -->
        <v-menu
          v-model="menu"
          :nudge-width="150"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center" v-bind="attrs" v-on="on">
              <v-icon class="mr-3">
                mdi-handshake
              </v-icon>
              {{ customerProjectSelected ? customerProjectSelected.name : '- Select Project -' }}
              <v-icon small class="ml-2">
                mdi-chevron-down
              </v-icon>
            </div>
          </template>
          <v-card>
            <v-list>
              <v-list-item v-for="(c, iC) in dataProject" :key="'c-b2b-' + iC" @click.prevent="customerProjectSelected = c">
                {{ c.name }}
              </v-list-item>
              <!-- <v-list-item>
                <v-text-field
                  outlined
                  hide-details
                  dense
                  label="Search customer"
                />
              </v-list-item> -->
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          class="dashboard-logout"
          dark
          icon
          color="red"
          style="display: none;"
          @click="DO_LOGOUT()"
        >
          <v-icon>
            mdi-power
          </v-icon>
        </v-btn>
        <v-btn
          dark
          text
          @click="$emit('close', true)"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div v-if="!customerProjectSelected" class="py-6 px-4 text-center" style="height: calc(100% - 64px);overflow-y: auto;">
      Please select Project
    </div>
    <div v-else style="height: calc(100% - 64px);overflow-y: auto;">
      <v-tabs
        v-model="tab"
        background-color="grey lighten-4"
        color="primary"
        centered
        grow
      >
        <v-tabs-slider color="secondary"></v-tabs-slider>

        <v-tab href="#tab-1" class="font-weight-bold">
          Dashboard
        </v-tab>

        <v-tab href="#tab-2" class="font-weight-bold">
          Tracking
        </v-tab>
        
        <v-tab href="#tab-3" class="font-weight-bold">
          Gallery
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" :touchless="true" class="business-tabs-items" style="height: calc(100% - 48px)!important;">
        <v-tab-item
          value="tab-1"
          class="fill-height"
          style="max-height: 100%!important;overflow: auto!important;width: 100%;max-width: 100%;"
        >
          <v-card outlined class="" style="min-width: 450px;">
            <v-card-text class="">
              <v-row v-if="dataResult" no-gutters>
                <v-col cols="12" class="col-sm-4 pa-2 pa-sm-4">
                  <div class="pa-4 rounded-lg bg-business-primary elevation-1">
                    <div class="text-body-2 grey--text text--lighten-4">
                      TOTAL
                    </div>
                    <div class="text-h4 white--text font-weight-bold">
                      {{ dataResult.total.toLocaleString() }}  <small>TTK</small>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" class="col-sm-4 pa-2 pa-sm-4">
                  <div class="pa-4 rounded-lg bg-business-primary elevation-1">
                    <div class="text-body-2 grey--text text--lighten-4">
                      SEND TO
                    </div>
                    <div class="text-h4 white--text font-weight-bold">
                      {{ graphActive ? '~' : dataResult.total_cities.toLocaleString() }} <small>Cities</small>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" class="col-sm-4 pa-2 pa-sm-4">
                  <div class="pa-4 rounded-lg bg-business-primary elevation-1">
                    <div class="text-body-2 grey--text text--lighten-4">
                      IN COVERAGE
                    </div>
                    <div class="text-h4 white--text font-weight-bold">
                      {{ dataResult.total_provinces.toLocaleString() }} <small>Provinces</small>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="col-sm-12 px-4 pb pt-2">
                  <div v-if ="lastUpdateData && lastUpdateData.recipient_time" class="text-center text-body-2 pb-4 primary--text d-flex fill-width justify-space-between align-center">
                    <span class="text-left caption">
                      Last Update :<br>{{ $localDT(lastUpdateData.recipient_time, 'datetime') }}
                    </span>
                    <div v-if="!$store.state.isCLient" class="d-flex flex-column justify-center">
                      <div class="font-weight-bold pb-2">
                        Media Files
                      </div>
                      <v-btn dense size="x-small" color="primary" class="caption rounded-pill elevation-0 py-1 mb-2" height="26px" @click.prevent="DownloadFiles()">
                        Download
                      </v-btn>
                      <v-btn dense size="x-small" color="bg-business-secondary" class="white--text caption rounded-pill elevation-0 py-1" height="26px" @click.prevent="DownloadFiles(true)">
                        Compress & Download
                      </v-btn>
                    </div>
                  </div>
                  <div class="grey lighten-1 rounded-xl">
                    <v-progress-linear
                      :value="((dataResult.progress.delivered * 100) / (dataResult.total))"
                      height="20"
                      dark
                      color="bg-business-primary darken-1"
                      class="rounded-xl"
                    >
                      <template v-slot:default="{ value }">
                        <strong>{{ value.toFixed(2) }}%</strong>
                      </template>
                    </v-progress-linear>
                  </div>
                </v-col>
                <v-col cols="12" class="col-sm-12 pa-4 p-relative">
                  <v-card outlined class="card-parent-chart rounded-lg border-grey pa-4 border p-relative" style="min-height:350px;" v-resize="onGraphResize">
                    <div style="position:absolute;right:25px;top:17px;z-index:1;">
                      <select v-model="graphActive" type="text" class="rounded-xl text-caption block bg-business-secondary white--text text-lighten-4 py-1 px-2 text-center c-pointer" style="width: 100px;outline: none;">
                        <option :value="0">- All -</option>
                        <option v-for="(g, gi) in dataProjectGroups" :key="'group-' + gi" :value="gi">{{ g ? gi : null }}</option>
                      </select>
                    </div>
                    <div class="content-schools">
                      <div v-for="(i, ii) in optionsBar.xaxis.categories" :class="['index-' + ii]" class="progress p-relative">
                        <span class="in-progress text-center" />
                        <span class="delivered" />
                      </div>
                    </div>
                    <apexchart v-if="graphRender" width="100%" height="450px" type="bar" key="apexchart-data-1" :options="optionsBar" :series="seriesBar" />
                  </v-card>
                  <div class="content-chart-header d-flex align-center font-weight-bold text-caption">
                    <div :style="{ width: wHeader.prov }" class="d-inline-block blue darken-4 white--text pa-2 text-truncate text-center">
                      Province
                    </div>
                    <div :style="{ width: wHeader.dest }" class="d-inline-block blue darken-3 white--text pa-2 text-truncate text-center">
                      Jumlah
                    </div>
                    <div class="d-inline-flex flex-grow-1 justify-center blue darken-4 white--text pa-2 text-truncate text-center">
                      Progress
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item
          value="tab-2"
          class="fill-height"
          style="max-height: 100%!important;overflow-y: auto!important;"
        >
          <v-card outlined class="elevation-0">
            <v-card-text class="px-0 py-4 pa-sm-4">
              <v-row no-gutters align="center">
                <v-col cols="12" class="col-sm-6 text-center text-sm-left pa-2 py-sm-0 bg-grey lighten-2">
                  <div class="px-4 px-sm-0 py-0 py-sm-4">
                    <input v-model="shippingGetData.query" type="text" spellcheck="false" placeholder="SEARCH" class="search-ttk rounded-xl text-subtitle-1 font-weight-bold bg-business-secondary elevation-1 block white--text py-2 px-4 px-sm-6 text-center text-sm-left" style="max-width: 100%;width: 300px;outline: none;">
                  </div>
                </v-col>
                <v-col cols="12" class="col-sm-6 text-center text-sm-right pa-2 pa-sm-0">
                  <div class="d-flex align-center justify-end pb-2 pt-0 pt-sm-2">
                    <select v-model.number="provincesDataSelected" type="text" spellcheck="false" class="mr-4 search-ttk rounded-xl text-subtitle-2 bg-business-secondary elevation-1 block white--text text-lighten-4 py-2 px-4 px-sm-6 text-center c-pointer" style="max-width: 100%;width: 300px;outline: none;">
                      <option :value="0">- All Provinces -</option>
                      <option v-for="(p, iP) in provincesData" :key="iP" :value="p.prov_id">{{ p.prov_name }}</option>
                    </select>
                    <BusinessXlsxExport :customerProjectSelectedID="customerProjectSelected ? customerProjectSelected.id : 0" :province="provincesDataSelected" :status-progress="filterStatus" />
                  </div>
                  <div class="d-flex flex-wrap align-center justify-center justify-sm-end fill-width">
                    <v-chip :class="[filterStatus === 0 ? 'secondary white--text' : '']" small class="px-6 c-ponter ma-1" @click.prevent="filterStatus = 0">
                      All
                    </v-chip>
                    <v-chip :class="[filterStatus === 1 ? 'secondary white--text' : '']" small class="px-6 c-ponter ma-1" @click.prevent="filterStatus = 1">
                      On Transport
                    </v-chip>
                    <v-chip :class="[filterStatus === 2 ? 'secondary white--text' : '']" small class="px-6 c-ponter ma-1" @click.prevent="filterStatus = 2">
                      On Delivery
                    </v-chip>
                    <v-chip :class="[filterStatus === 3 ? 'secondary white--text' : '']" small class="px-6 c-ponter ma-1" @click.prevent="filterStatus = 3">
                      Delivered
                    </v-chip>
                  </div>
                </v-col>
                <v-col cols="12" class="col-sm-12 pa-2">
                  <div class="rounded-lg elevation-1 pa-4 border clean-table">
                    <v-data-table
                      :headers="shippingGetData.headers"
                      :items="shippingGetData.data"
                      item-key="id"
                      :server-items-length="shippingGetData.total"
                      :page.sync="shippingGetData.page"
                      :items-per-page.sync="shippingGetData.limit"
                      :options.sync="shippingGetData.options"
                      :footer-props="{
                        itemsPerPageOptions: [25]
                      }"
                      :loading="shippingGetData.loading"
                      loading-text="Loading... Please wait"
                    >
                      <template v-slot:[`item.ttk`]="{ item }">
                        <div class="py-2">
                          <div class="d-flex align-center text-uppercase">
                            <v-icon class="mr-1">
                              mdi-barcode
                            </v-icon>
                            <span class="font-weight-bold caption">
                              {{ item.ttk || '-' }}
                            </span>
                          </div>
                          <div class="d-flex align-center caption secondary--text text-uppercase font-weight-bold">
                            <v-icon small class="mr-1" color="secondary">
                              mdi-package
                            </v-icon>
                            {{ item.product_name || '-' }}
                            <!-- {{ item.sales_detail || '-' }} -->
                          </div>
                        </div>
                      </template>
                      <template v-slot:[`item.option`]="{ item }">
                        <div class="d-flex align-center flex-sm-column" style="min-width: 100px;">
                          <v-btn v-if="!parseInt(item.old_data)" depressed small dark color="bg-business-secondary" class="text-capitalize" @click.prevent="PLAN_SHOW(item)">
                            <v-icon small class="mr-1">
                              mdi-format-list-checkbox
                            </v-icon>
                            Tracking
                          </v-btn>
                          <v-btn v-if="parseInt($statusPlan(item.manifest_plan).status)" depressed small dark color="bg-business-secondary" class="text-capitalize mt-2" @click.prevent="DOWNLOAD_FILES_SEPARATE(parseInt($statusPlan(item.manifest_plan).id))">
                            {{ parseInt($statusPlan(item.manifest_plan).recipient_downloaded) ? 'Download' : 'Sync & Download' }}
                          </v-btn>
                        </div>
                      </template>
                      <template v-slot:[`item.shipping_origin_city_name`]="{ item }">
                        <div class="d-flex flex-column flex-sm-row align-center py-2">
                          <div>
                            {{ item.shipping_sender_name || '-' }}
                            <div>
                              {{ item.shipping_origin_city_name }} {{ item.shipping_origin_city_type }}
                            </div>
                          </div>
                          <v-icon class="mx-1" small>
                            mdi-chevron-right
                          </v-icon>
                          <div class="text-caption">
                            <strong>{{ item.shipping_name || '-' }}</strong>
                            <div>
                              {{ item.shipping_address }}
                            </div>
                            <div>
                              {{ item.shipping_destination_city_name }} {{ item.shipping_destination_city_type }}
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-slot:[`item.status`]="{ item }">
                        <div v-if="!parseInt(item.old_data)" class="py-2">
                          <TrackStatus :item="item" @reload="SHIPPING_GET()" />
                          <div v-if="item.manifest_detail">
                            <div v-if="!parseInt($statusPlan(item.manifest_plan).status_plan)">
                              <div v-if="$ParseManifest(item.manifest_detail).type === 'courier'">
                                <div class="pt-2">
                                  <v-btn depressed color="bg-business-primary" dark small class="confirm-delivery-and-update rounded-pill text-capitalize px-6" @click.prevent="ConfirmDelivered(item.manifest_plan, item.shipping_name)">
                                    {{ parseInt($statusPlan(item.manifest_plan).status) ? 'Update Delivery' : 'Confirm Delivered' }}
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="red--text">
                          ANTERO DATA
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item
          value="tab-3"
          class="fill-height"
          style="max-height: 100%!important;overflow-y: auto!important;"
        >
          <v-card outlined class="elevation-0">
            <v-card-text class="py-4 pa-sm-4">
              <v-row align="center">
                <v-col cols="8" class="col-sm-6 text-center text-sm-left bg-grey lighten-2">
                  <div class="px-0 py-0 py-sm-4">
                    <input v-model="documentation.query" type="text" spellcheck="false" placeholder="SEARCH" class="search-ttk rounded-xl text-subtitle-1 font-weight-bold bg-business-secondary elevation-1 block white--text py-2 px-4 px-sm-6 text-center text-sm-left fill-width fill-height" style="max-width: 100%;outline: none;">
                  </div>
                </v-col>
                <v-col cols="4" class="col-sm-2 text-center text-sm-left bg-grey lighten-2">
                  <div class="px-0 py-0 py-sm-4 fill-height">
                    <select v-model="documentation.category" type="text" spellcheck="false" class="search-ttk rounded-xl text-subtitle-2 bg-business-secondary elevation-1 block white--text text-lighten-4 py-2 px-4 px-sm-6 c-pointer fill-width fill-height" style="max-width: 100%;outline: none;">
                      <option value="">SEMUA</option>
                      <option v-for="(g, gi) in dataProjectGroups" :key="'group-' + gi" :value="gi">{{ g ? gi : null }}</option>
                    </select>
                  </div>
                </v-col>
                <v-col v-if="!$store.state.isCLient" cols="12" class="col-sm-4 text-center text-sm-right bg-grey lighten-2">
                  <div class="px-4 px-sm-0 py-0 py-sm-4">
                    <v-btn :loading="loadingUpload" class="rounded-pill" color="primary" @click.prevent="DOCUMENTATION_OPEN(null)">
                      + Upload
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0 pb-4">
                  <v-divider />
                </v-col>
                <v-col v-if="!documentation.data.length" cols="12" class="py-8 px-4 text-center text--grey darken-1">
                  <!-- <v-progress-circular
                    indeterminate
                    color="amber"
                  ></v-progress-circular> -->
                  no data
                </v-col>
                <v-col
                  v-for="(d, iN) in documentation.data"
                  :key="'documentation-' + iN"
                  cols="12"
                  class="col-sm-4"
                >
                  <div class="c-pointer white elevation-1 rounded-lg" style="overflow:hidden;" @click.prevent="DOCUMENTATION_OPEN(d)">
                    <v-img
                      :src="(d.files || '').match(/\.mp4/) ? '' : (d.files ? $StrToArray(d.files)[0] : '')"
                      :aspect-ratio="1.5/1"
                      cover
                    >
                      <template v-if="(d.files || '').match(/\.mp4/)" v-slot:placeholder>
                        <video width="100%" height="100%" class="" style="position:absolute;min-height:100%;min-width:100%;object-fit: cover;">
                          <source :src="$StrToArray(d.files || '[]') ? $StrToArray(d.files)[0] : null" :type="'video/' + $ext($StrToArray(d.files) ? $StrToArray(d.files)[0] : '.mp4')">
                          Your browser does not support the video tag.
                        </video>
                      </template>
                      <v-chip v-if="d.category" color="secondary" class="elevation-1" size="x-small" style="position:absolute;top:20px;right:20px;">
                        {{ d.category }}
                      </v-chip>
                    </v-img>
                    <v-divider />
                    <div class="py-2 px-4 font-weight-bold text-body-2 text-capitalize text-sm-body-1 text--grey text--darken-2 text-center fill-width text-truncate">
                      {{ d.title || '' }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-pagination
                    v-model.number="documentation.page"
                    :total-visible="5"
                    :length="Math.ceil((parseInt(documentation.total) || 0) / 6)"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-dialog
      v-model="dialogDelivered.show"
      scrollable
      max-width="450px"
    >
      <v-card v-if="dialogDelivered.show" class="rounded-lg">
        <v-card-title class="justify-center subtitle-2 align-center font-weight-bold">
          <v-icon class="mr-2" color="primary">
            mdi-truck-fast
          </v-icon>
          Confirm Delivered
        </v-card-title>
        <v-divider class="mt-1" />
        <v-card-text v-if="dialogDelivered.data" style="max-height: 90vh;">
          <div class="pt-4">
            <div class="d-flex flex-column fill-width pb-4">
              <div class="d-flex align-center justify-center fill-width grey--text pb-4 pt-1">
                <div class="d-block font-weight-bold rounded-lg white--text bg-business-primary pa-6 c-pointer fill-width text-center text-capitalize" @click.prevent="GET_FILE()">
                  <v-icon class="mr-2" color="white" small>
                    mdi-camera-outline
                  </v-icon>
                  Upload Files
                </div>
              </div>
              <div class="d-flex flex-wrap justify-center fill-width">
                <div v-for="(ir, iRe) in $StrToArray(dialogDelivered.data.recipient_imgs)" :key="'img-courier-' + iRe" class="p-relative grey lighten-4 ma-1 rounded-lg" style="width:89px;height:89px;">
                  <v-btn icon x-small color="red" class="rounded-circle c-pointer d-flex align-center justify-center" style="background-color:rgba(255,0,0,.1);position: absolute;top: 2px;right:2px;padding: 4px;z-index:1;pointer-events:all;" @click.prevent="DeleteImage(ir)">
                    <v-icon color="red" x-small>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                  <a :href="ir" target="_blank" class="d-flex align-center justify-center fill-width fill-height pa-1">
                    <v-icon
                      v-if="(ir || '').match(/\.pdf/gi)"
                    >
                      mdi-file-pdf-box
                    </v-icon>
                    <v-img
                      v-else
                      :src="ir"
                      aspect-ratio="1"
                      class="grey lighten-2 rounded-lg"
                      style="width: 85px!important;max-width: 85px!important"
                    />
                  </a>
                </div>
              </div>
            </div>
            <v-text-field
              v-model="dialogDelivered.data.recipient_name"
              label="Nama Penerima"
              outlined
              hide-details
              class="rounded-lg mb-4"
            />
            <v-select
              v-model="dialogDelivered.data.recipient_relation"
              label="Role"
              :items="['Pengelola Perpustakaan', 'Petugas Perpustakaan', 'Kepala Desa', 'Petugas Desa', 'Lainnya']"
              outlined
              hide-details
              class="rounded-lg mb-4"
            />
            <v-text-field
              v-model="dialogDelivered.data.recipient_notes"
              label="Catatan"
              outlined
              hide-details
              class="rounded-lg mb-4"
            />
            <input
              v-model="dialogDelivered.data.recipient_time"
              type="datetime-local"
              min="2024-01-01T00:00"
              :max="$localDT(null, 'datetimedefault')"
              class="pa-3 rounded-lg d-block fill-width grey lighten-3 c-pointer"
              style="outline:none;"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4">
          <v-btn
            color="red"
            outlined
            small
            class="rounded-pill text-capitalize caption px-4"
            @click="dialogDelivered.show = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            small
            class="rounded-pill text-capitalize caption px-4"
            @click="ProcessDelivered()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loadingUpload"
      hide-overlay
      persistent
      width="300"
      content-class="rounded-lg2"
    >
      <v-card
        dark
        color="primary"
        class="rounded-lg2"
      >
        <v-card-text class="d-flex justify-center align-center flex-column pt-4 rounded-lg2 white--text">
          Uploading, please wait!
          <div class="px-4 fill-width">
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0 mt-2 rounded-lg2"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loadingDownload"
      hide-overlay
      persistent
      width="300"
      content-class="rounded-lg2"
    >
      <v-card
        dark
        color="primary"
        class="rounded-lg2"
      >
        <v-card-text class="d-flex justify-center align-center flex-column pt-4 rounded-lg2 white--text">
          Compressing file ... please wait!
          <div class="px-4 fill-width">
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0 mt-2 rounded-lg2"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="documentation.dialog.show"
      scrollable
      max-width="450px"
    >
      <v-card v-if="documentation.dialog.show" class="rounded-lg">
        <v-card-title class="justify-center subtitle-1 align-center font-weight-bold">
          <v-icon class="mr-2" color="primary">
            mdi-folder-multiple-image
          </v-icon>
          Gallery
        </v-card-title>
        <v-divider class="mt-1" />
        <v-card-text v-if="documentation.dialog.data" style="max-height: 90vh;" class="pa-0">
          <div class="">
            <div class="d-flex flex-column fill-width">
              <div class="d-flex flex-column align-center justify-center fill-width grey--text pb-2">
                <div v-if="$StrToArray(documentation.dialog.data.files).length" class="d-block font-weight-bold white--text c-pointer fill-width text-center text-capitalize">
                  <video v-if="(documentation.dialog.data.files || '').match(/\.mp4/)" width="100%" height="auto" class="" controls>
                    <source :src="$StrToArray(documentation.dialog.data.files)[0]" :type="'video/' + $ext($StrToArray(documentation.dialog.data.files)[0])">
                    Your browser does not support the video tag.
                  </video>
                  <img
                    v-else
                    class="d-block fill-width"
                    :src="(documentation.dialog.data.files ? $StrToArray(documentation.dialog.data.files)[0] : '')"
                  >
                </div>
                <div v-if="!$store.state.isCLient" class="d-flex items-center py-4 c-pointer text--primary subtitle-2" @click.prevent="UploadPrepare()">
                  <v-icon class="mr-2" color="primary" small>
                    mdi-video-plus-outline
                  </v-icon>
                  {{ $StrToArray(documentation.dialog.data.files).length ? 'Change Media' : 'Upload Media' }}
                </div>
              </div>
            </div>
            <div v-if="!$store.state.isCLient" class="px-4">
              <v-text-field
                v-model="documentation.dialog.data.title"
                label="Judul"
                outlined
                dense
                hide-details
                class="rounded-lg mb-4"
              />
              <v-select
                v-model="documentation.dialog.data.category"
                :items="Object.keys(dataProjectGroups)"
                label="Group"
                outlined
                dense
                hide-details
                class="rounded-lg mb-4"
              />
              <v-textarea
                v-model="documentation.dialog.data.description"
                label="Deskripsi"
                outlined
                dense
                hide-details
                class="rounded-lg mb-4"
              />
            </div>
            <div v-else class="px-4 pb-8">
              <div class="font-weight-bold text-subtitle-1 text--black text-capitalize">
                {{ documentation.dialog.data.title || '' }}
              </div>
              <div style="white-space: pre-wrap;width:100%;" v-html="documentation.dialog.data.description || ''" />
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4">
          <v-spacer v-if="$store.state.isCLient" />
          <v-btn
            color="red"
            outlined
            small
            class="rounded-pill text-capitalize caption px-4"
            @click="documentation.dialog.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="!$store.state.isCLient"
            color="primary"
            depressed
            small
            class="rounded-pill text-capitalize caption px-4"
            @click="DOCUMENTATION_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogPlan :show="dialogPlan.show" :selected="dialogPlan.selected" @close="dialogPlan.show = false" />
  </v-card>
</template>

<style lang="scss">
.v-application {
  .search-ttk {
    text-transform: uppercase;
    &::placeholder {
      color: rgba(255, 255, 255, .7)!important;
      font-weight: normal!important;
    }
    &.subtitle {
      letter-spacing: 2px!important;
    }
  }
}
.clean-table {
  table {
    thead {
      &.v-data-table-header {
        border-radius: 10px!important;
        overflow: hidden!important;
        background-color: #e75d00;
        background: #262d44;
        background: linear-gradient(145deg, rgba(22,56,73,1) 30%, rgb(251, 140, 0) 100%);
        >tr {
          background-color: transparent!important;
          // >th {
          //   >span {
          //     // color: #262d44 !important;
          //   }
          // }
        }
      }
    }
  }
}
select option {
  margin: 0px;
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 12px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.apexcharts-legend {
  &.apx-legend-position-bottom {
    .apexcharts-legend-series {
      padding: 6px 12px;
      min-width: 145px;
      border-radius: 4px!important;
      background-color: rgba(0, 0, 0, .05);
      strong {
        font-size: 12px;
      }
      .font-weight-bold {
        font-size: 10px;
      }
      // &:nth-child(1) {
      //   background-color: #e75d00;
      // }
      // &:nth-child(2) {
      //   background-color: #262d44;
      // }
      // &:nth-child(3) {
      //   background-color: #8dc129;
      // }
    }
  }
}

.content-chart-header {
  position: absolute;
  // visibility: hidden;
  z-index: 2;
  top: 68px;
  left:4.6%;
  width:91.6%;
  >div {
    transition: all .2s ease-in-out;
  }
}
.content-schools {
  position: absolute;
  visibility: hidden;
  // left: 15.3%;
  z-index: 1;
  transition: width .15s ease-in-out;
  >.progress {
    transition: width .15s ease-in-out;
    >span {
      transition: width .15s ease-in-out;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0px;
      height: 100%;
      font-size: 10px;
      // line-height: 90%;
      font-weight:bold;
      &.in-progress {
        left: 0px;
        // background-color: rgba(0, 36, 155, 0.85);
        // background-color: rgba(38,45,68,0.85);
        color: rgba(0, 36, 155, 1);
      }
      &.delivered {
        right: 0px;
        background-color: rgba(141,193,41,0.85);
      }
    }
  }
}
.apexcharts-yaxis>.apexcharts-yaxis-texts-g>text {
  position: relative;
  >.c-content {
    position: absolute;
    left: 10px;
    width: 100px;
    z-index: 1;
    background-color: #ff0000;
    height: 8px;
  }
}
</style>
